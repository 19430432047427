.sidebar {
  position: relative;
  width: 100%;
  @apply flex justify-center items-center my-[4vh];
}

.sidebar-item {
  margin: 0;
  margin-right: 5px;
  @apply mb-0;
}

.sidebar-icon > svg {
  font-size: 1.3rem;
}

.sidebar-item:hover .sidebar-icon {
  @apply text-primary border-primary;
}

.sidebar-item:last-child {
  @apply m-0;
}

@media (min-width: 600px) {
  .sidebar-item {
    @apply mb-0;
  }
}

@media (min-width: 768px) {
  .sidebar {
    @apply absolute transform top-1/2 -translate-y-1/2 z-20;
    right: 5%;
  }

  .sidebar-item {
    @apply mb-3 inline-block;
  }
}

@media (min-width: 1024px) {
  .sidebar {
    position: fixed;
    width: auto;
    right: 5%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
