* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background: white;
}

body {
  overflow-x: hidden;
}

h1 {
  @apply font-display;
}
