button {
  @apply outline-none;
}

button:focus {
  @apply outline-none;
}

body > iframe {
  display: none;
  z-index: -2147483647 !important;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
