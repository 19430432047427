h1 {
  @apply font-body;
}

.heading-1 {
  top: 21%;
  @apply mb-2;
}

@media (min-width: 600px) {
  .heading-1 {
    top: 17%;
    @apply mb-10;
  }
}
