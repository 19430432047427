@font-face {
  font-family: 'VHS Glitch 2';
  src: url('../assets/fonts/vhs-glitch-2-wave/wave.otf') format('otf'),
    url('../assets/fonts/vhs-glitch-2-wave/wave.woff') format('woff'),
    url('../assets/fonts/vhs-glitch-2-wave/wave.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Cond Super';
  src: url('../assets/fonts/drukcond-super/regular.otf') format('otf'),
    url('../assets/fonts/drukcond-super/regular.woff') format('woff'),
    url('../assets/fonts/drukcond-super/regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Wide';
  src: url('../assets/fonts/drukcond-super/medium.otf') format('otf'),
    url('../assets/fonts/drukcond-super/medium.woff') format('woff'),
    url('../assets/fonts/drukcond-super/medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
