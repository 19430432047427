.tv-container {
  top: 30%;
  @apply m-0;
  width: 90%;
  min-height: 50vh;
  top: 35%;
}

@media (min-width: 480px) {
  .tv-container {
    height: 430px;
    width: 420px;
    top: 30%;
  }
}

@media (min-width: 640px) {
  .tv-container {
    height: 440px;
    width: 430px;
    top: 30%;
  }
}

@media (min-width: 768px) {
  .tv-container {
    @apply m-0;
    height: 470px;
    width: 460px;
  }
}

@media (min-width: 1024px) {
  .tv-container {
    top: 35%;
    width: 800px;
    height: 340px;
  }
}

@media (min-width: 1280px) {
  .tv-container {
    min-height: 60vh;
    height: auto;
    width: 70% !important;
  }
}

/* @media (min-width: 1536px) {
  .tv-container {
    width: 1200px;
    height: 550px;
  }
} */

/* tv frame */
.tv {
  @apply w-full h-full relative;
  border-radius: 50% / 5%;
  overflow: hidden;
}

.tv::before {
  @apply absolute;
  content: '';
  top: 10%;
  bottom: 10%;
  right: -5%;
  left: -5%;
  border-radius: 5% / 50%;
}
